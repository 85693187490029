<template>
  <div>
    <h4 class="pb-4">Latest Meter Installed</h4>
    <div class="table-responsive">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">Account No</th>
          <th scope="col">Consumer Name</th>
          <th scope="col">Meter Number</th>
          <th scope="col">Install Date</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="meter in latestMeters" :key="meter.id">
          <td>
            <router-link :to="'/consumers/' +  meter.customer_account_id">
              {{ meter.customer_account.account_number }}
            </router-link>
          </td>

          <td> {{ meter.customer_account.first_name }}</td>
          <td>
            <router-link :to="'/meters/' +  meter.id">
              {{ meter.hardware_serial_no }}
            </router-link>
          </td>
          <td>{{ meter.installing_date | dateFormat }}</td>
        </tr>
        </tbody>
      </table>
    </div>
    <router-link to="/meters">
      <button type="button" class="btn btn-primary float-right view-all">
        View all
      </button>
    </router-link>
  </div>
</template>

<script>
import {freeSet} from "@coreui/icons";
import {mapGetters} from "vuex";

export default {
  freeSet,
  computed: {
    ...mapGetters("Meter", ["latestMeters"]),
  },
  mounted() {
    this.$store.dispatch("Meter/getLatestMeters");
  },
};
</script>

<style lang="scss" scoped>
@media (width: 1366px) {
  div {
    button {
      margin-top: 20px;
    }
  }
}
</style>


